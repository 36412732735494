<template>
	<!-- v-if="canViewVerticalNavMenuLink(item)" -->
	<!-- sidebar del Principal -->
	<li
		class="nav-item"
		:class="{
			active: isActive,
			disabled: item.disabled,
		}"
	>
		<!-- item.requiresInvitado == isInvitado -->
		<b-link v-bind="linkProps" class="d-flex align-items-center">
			<feather-icon
				:icon="item.icon || 'CircleIcon'"
				v-if="
					item.title !== 'Pollos' &&
					item.title !== 'Ejemplares' &&
					item.title !== 'Encaste' &&
					item.title !== 'Enfermeria'
				"
			/>
			<img
				src="@/assets/images/icons/pollo_b11.png"
				alt=""
				v-if="item.title == 'Pollos' && (layout.skin == 'dark' || layout.skin == 'semi-dark')"
				class="mr-1"
				style="width: 22px"
			/>
			<img
				src="@/assets/images/icons/pollo_n11.png"
				alt=""
				v-if="item.title == 'Pollos' && (layout.skin == 'light' || layout.skin == 'bordered')"
				class="mr-1"
				style="width: 22px; filter: brightness(0.5)"
			/>
			<img
				src="@/assets/images/icons/iconos-02.png"
				alt=""
				v-if="item.title == 'Ejemplares' && (layout.skin == 'dark' || layout.skin == 'semi-dark')"
				class="mr-1"
				style="width: 23px"
			/>
			<img
				src="@/assets/images/icons/iconos-05.png"
				alt=""
				v-if="item.title == 'Ejemplares' && (layout.skin == 'light' || layout.skin == 'bordered')"
				class="mr-1"
				style="width: 23px; filter: brightness(0.5)"
			/>
			<img
				src="@/assets/images/icons/iconos-03.png"
				alt=""
				v-if="item.title == 'Encaste' && (layout.skin == 'dark' || layout.skin == 'semi-dark')"
				class="mr-1"
				style="width: 23px"
			/>
			<img
				src="@/assets/images/icons/iconos-06.png"
				alt=""
				v-if="item.title == 'Encaste' && (layout.skin == 'light' || layout.skin == 'bordered')"
				class="mr-1"
				style="width: 23px; filter: brightness(0.5)"
			/>
			<img
				src="/training/icon.svg"
				alt=""
				v-if="item.title == 'Preparacion' && (layout.skin == 'light' || layout.skin == 'bordered')"
				class="mr-1"
				style="width: 23px; filter: brightness(0.5)"
			/>
			<img
				src="@/assets/images/icons/nursery-black.svg"
				alt=""
				v-if="item.title == 'Enfermeria' && (layout.skin == 'light' || layout.skin == 'bordered')"
				class="mr-1"
				style="width: 23px; filter: brightness(0.5)"
			/>
			<span class="menu-title text-truncate">{{ t(item.title) }}</span>
			<b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
				{{ item.tag }}
			</b-badge>
		</b-link>
	</li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl"
import { BLink, BBadge } from "bootstrap-vue"
import { useUtils as useI18nUtils } from "@core/libs/i18n"
import useVerticalNavMenuLink from "./useVerticalNavMenuLink"
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink"

export default {
	components: {
		BLink,
		BBadge,
	},
	mixins: [mixinVerticalNavMenuLink],
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	computed: {
		layout() {
			return this.$store.getters["appConfig/layout"]
		},
	},
	setup(props) {
		const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
		const { t } = useI18nUtils()
		const { canViewVerticalNavMenuLink } = useAclUtils()

		return {
			isActive,
			linkProps,
			updateIsActive,

			// ACL
			canViewVerticalNavMenuLink,

			// i18n
			t,
		}
	},
}
</script>
