export default [
    {
        title: "Ejemplares",
        icon: "GitlabIcon",
        route: "specimens-component",
        // requiresAdmin: true,
        // requiresEncastador: true,
        // requiresInvitado: true,
        roles: [1,4,6,7]
    }
];
