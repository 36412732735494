<template>
	<layout-vertical>
		<router-view />

		<app-customizer v-if="showCustomizer" slot="customizer" />

		<template slot="sliders-modal">
			<images-sliders-total />
		</template>

		<template slot="loading-sliders-upload">
			<loading-in-the-background />
		</template>
	</layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue"
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue"
import ImagesSlidersTotal from "../../views/amg/header-bookmarks/ImagesSlidersTotal.vue"
import LoadingInTheBackground from "../../views/amg/header-bookmarks/LoadingInTheBackground"
import { $themeConfig } from "@themeConfig"
import { mapState } from "vuex"

export default {
	components: {
		AppCustomizer,
		LayoutVertical,
		ImagesSlidersTotal,
		LoadingInTheBackground,
	},
	data() {
		return {
			showCustomizer: $themeConfig.layout.customizer,
		}
	},
	computed: {
		...mapState("header_book", ["backgroundUpload"]),
	},
	mounted() {
		this.loadSeaons()
	},
}
</script>

<style lang="scss" scoped></style>
