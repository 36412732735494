export default [
    {
        title: "Torneos",
        icon: "AwardIcon",
        // route: "tournament-component",
        route: "tournaments",
        requiresAdmin: false,
        requiresInvitado: false,
        requiresEncastador: false,
        roles: [1,4,6]
    }
];
