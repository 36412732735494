export default [
    {
        title: "Usuarios",
        icon: "UserIcon",
        requiresAdmin: true,
        requiresInvitado: true,
        roles: [1],
        children: [
            {
                title: "Usuarios del sistema",
                route: "users-amg",
                requiresAdmin: true,
                requiresInvitado: true,
                requiresEncastador: true
            },
            {
                title: "Colaboradores externos",
                route: "users-guest-list",
                requiresAdmin: true,
                requiresInvitado: true,
                requiresEncastador: true
            },
        ]
    }
];
