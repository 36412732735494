<template>
	<div
		class="main-menu menu-fixed menu-accordion menu-shadow"
		:class="[
			{
				expanded:
					!isVerticalMenuCollapsed ||
					(isVerticalMenuCollapsed && isMouseHovered),
			},
			skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
		]"
		@mouseenter="updateMouseHovered(true)"
		@mouseleave="updateMouseHovered(false)"
	>
		<!-- main menu header-->
		<div class="navbar-header expanded mb-2">
			<slot
				name="header"
				:toggleVerticalMenuActive="toggleVerticalMenuActive"
				:toggleCollapsed="toggleCollapsed"
				:collapseTogglerIcon="collapseTogglerIcon"
			>
				<ul class="nav navbar-nav flex-row">
					<!-- Logo & Text -->
					<li class="nav-item mr-auto">
						<b-link class="navbar-brand" to="/">
							<div class="d-flex align-items-center">
								<span class="brand-logo">
									<b-img
										:src="appLogoImage"
										alt="logo"
										style="filter: brightness(1.5)"
									/>
								</span>
								<span>
									<h2 class="brand-text">
										{{ appName }}
									</h2>
								</span>
							</div>
						</b-link>
					</li>

					<!-- Toggler Button -->
					<!-- <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li> -->
				</ul>
			</slot>
		</div>
		<!-- / main menu header-->

		<!-- Shadow -->
		<div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

		<!-- main menu content-->
		<VuePerfectScrollbar
			:settings="perfectScrollbarSettings"
			class="main-menu-content scroll-area"
			tagname="ul"
			@ps-scroll-y="
				(evt) => {
					shallShadowBottom = evt.srcElement.scrollTop > 0;
				}
			"
		>
			<VerticalNavMenuItems
				:items="navMenuItems"
				class="navigation navigation-main"
			/>
		</VuePerfectScrollbar>
		<!-- /main menu content-->
	</div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
	//     data(){
	//         var self = this;
	//         return{
	//             navMenuItems: [
	//                 {
	//                     title: "Usuarios",
	//                     icon: "UserIcon",
	//                     route: "users-amg",
	//                 },
	//                 {
	//                     title: "Ejemplares",
	//                     icon: "GitlabIcon",
	//                     route: "ejemplares-sr",
	//                 },
	//                 {
	//                     title: 'Encaste',
	//                     icon: 'CropIcon',
	//                     tag: '2',
	//                     tagVariant: 'light-warning',
	//                     children: [
	//                         {
	//                             title: 'Posturas',
	//                             route: 'posturas-sr',
	//                         },
	//                         {
	//                             title: 'Huevos',
	//                             route: 'regsiter-eggs',
	//                         },
	//                         {
	//                             title: 'Lotes',
	//                             route: 'lotes-sr',
	//                             tag: 0,
	//                             tagVariant: 'light-warning',
	//                         },
	//                         {
	//                             title: 'Migración',
	//                             route: 'migracion-sr',
	//                         }
	//                     ],
	//                 },
	//             ]
	//         }
	//     },
	//   mounted() {
	//       this.$store.dispatch("encaste/getLoteNotifications").then(res=>{
	//           this.navMenuItems[2].children[2].tag = res.data;
	//       });
	//   },
	components: {
		VuePerfectScrollbar,
		VerticalNavMenuItems,
		BLink,
		BImg,
	},
	props: {
		isVerticalMenuActive: {
			type: Boolean,
			required: true,
		},
		toggleVerticalMenuActive: {
			type: Function,
			required: true,
		},
	},
	setup(props) {
		const {
			isMouseHovered,
			isVerticalMenuCollapsed,
			collapseTogglerIcon,
			toggleCollapsed,
			updateMouseHovered,
		} = useVerticalNavMenu(props);

		const { skin } = useAppConfig();

		// Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
		const shallShadowBottom = ref(false);

		provide("isMouseHovered", isMouseHovered);

		const perfectScrollbarSettings = {
			maxScrollbarLength: 60,
			wheelPropagation: false,
		};

		const collapseTogglerIconFeather = computed(() =>
			collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
		);

		// App Name
		const { appName, appLogoImage } = $themeConfig.app;

		return {
			navMenuItems,
			perfectScrollbarSettings,
			isVerticalMenuCollapsed,
			collapseTogglerIcon,
			toggleCollapsed,
			isMouseHovered,
			updateMouseHovered,
			collapseTogglerIconFeather,

			// Shadow Bottom
			shallShadowBottom,

			// Skin
			skin,

			// App Name
			appName,
			appLogoImage,
		};
	},
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
