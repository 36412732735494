export default [
	{
		title: "Compras",
		icon: "ShoppingBagIcon",
		children: [
			{
				title: "Lista",
				route: "orders-list-sr",
				requiresAdmin: true,
				requiresInvitado: true,
				requiresEncastador: false,
				roles: [1, 2],
			},
			{
				title: "Nuevo",
				route: "orders-cart-sr",
				requiresAdmin: true,
				requiresInvitado: true,
				requiresEncastador: false,
				roles: [1, 2],
			},
		],
		requiresAdmin: true,
		requiresInvitado: true,
		requiresEncastador: true,
		roles: [1, 2],
	},
];
