export default [
	{
		title: "Inventario",
		icon: "ArchiveIcon",
		children: [
			{
				title: "Ejemplares",
				icon: "GitlabIcon",
				route: "inventory-specimens-list-sr",
				roles: [1,2, 4, 6],
			},
			// {
			// 	title: "Pollos",
			// 	route: "inventory-chicks-list-sr",
			// 	icon: "GitlabIcon",
			// 	roles: [1, 4, 5, 6],
			// },
		],
		roles: [1,2, 4, 6],
	},
];
