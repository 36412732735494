<template>
	<ul>
		<template v-for="(item, index) in links">
			<component v-if="item.access" :is="resolveNavItemComponent(item)" :key="index" :item="item" />
		</template>
	</ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

export default {
	mounted() {},
	components: {
		VerticalNavMenuHeader,
		VerticalNavMenuLink,
		VerticalNavMenuGroup,
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			links: [],
		};
	},
	mounted() {
		const { roles } = this.$store.state.auth.currentUser;
		this.links = this.items.map((it) => {
			let access = false;
			// console.log("it.roles", it.roles);
			if (it.roles) {
				it.roles.forEach((rol) => {
					roles.forEach((rolRoute) => {
						if (rol == rolRoute) {
							access = true;
						}
					});
				});
			} else {
				access = false;
			}

			if (it.title == "Compras" && !this.isPreparador) {
				access = true;
				if (this.isSupervisor) {
					it.children = [
						{
							title: "Lista",
							route: "orders-list-sr",
							requiresAdmin: true,
							requiresInvitado: true,
							requiresEncastador: false,
							roles: [1, 2],
						},
						{
							title: "Nuevo",
							route: "orders-cart-sr",
							requiresAdmin: true,
							requiresInvitado: true,
							requiresEncastador: false,
							roles: [1, 2],
						},
					];
				} else {
					it.children = [
						{
							title: "Lista",
							route: "orders-list-sr",
							requiresAdmin: true,
							requiresInvitado: true,
							requiresEncastador: false,
							roles: [1, 2],
						},
					];
				}
				if (this.isInvitado) access = false;
			}

			return {
				...it,
				access: access,
			};
		});
	},
	setup() {
		provide("openGroups", ref([]));

		return {
			resolveNavItemComponent,
		};
	},
};
</script>
