export default [
	{
		title: "Videoteca",
		icon: "FilmIcon",
		route: "videolibrary-specimens-sr",
		requiresAdmin: true,
		requiresEncastador: false,
		requiresInvitado: true,
		roles: [1, 4, 6],
	},
];
