// import dashboard from "./dashboard";
// import appsAndPages from "./apps-and-pages";
// import others from "./others";
// import chartsAndMaps from "./charts-and-maps";
// import uiElements from "./ui-elements";
// import formAndTable from "./forms-and-table";
import users from "./sidebar-amg/users"
import specimens from "./sidebar-amg/specimens"
import preparacion from "./sidebar-amg/preparacion"
import administrative from "./sidebar-amg/administrative";
import inventory from "./sidebar-amg/inventory"
// import deads from "./sidebar-amg/deads";
import videolibrary from "./sidebar-amg/videolibrary"
import encaste from "./sidebar-amg/encaste"
import tournaments from "./sidebar-amg/tournaments"
import reports from "./sidebar-amg/reports"
import orders from "./sidebar-amg/orders"
import nursery from "./sidebar-amg/nursery"
import brain from "./sidebar-amg/brain"

// Array of sections
export default [
	...specimens,
	...encaste,
	...preparacion,

	...administrative,

	...orders,
	...inventory,
	// ...reports,
	...users,
	...videolibrary,
	...tournaments,
	...nursery,
	...brain,
	// ...dashboard,
	// ...appsAndPages,
	// ...uiElements,
	// ...formAndTable,
	// ...chartsAndMaps,
	// ...others
]
