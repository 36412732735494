export default [
	{
		title: "Enfermeria",
		icon: "HeartIcon",
		route: "nursery-sr",
		requiresAdmin: false,
		requiresInvitado: false,
		requiresEncastador: false,
		roles: [1, 12],
	},
]
