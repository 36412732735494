export default [
	{
		title: "Encaste",
		icon: "CropIcon",
		children: [
			{
				title: "Dashboard",
				icon: "BarChart2Icon",
				route: "dashboard-encaste-sr",
				roles: [1, 4, 6],
			},
			{
				title: "Registro de huevos",
				route: "regsiter-eggs",
				roles: [1, 4, 6],
			},
			{
				title: "Posturas",
				route: "posturas-sr",
				icon: "CpuIcon",
				roles: [1, 4, 6],
			},
			{
				title: "Lotes",
				icon: "GitBranchIcon",
				route: "lotes-sr",
				roles: [1, 4, 6],
			},
			{
				title: "Pollos",
				icon: "GitBranchIcon",
				route: "pollos-sr",
				roles: [1, 4, 6],
			},
			{
				title: "Corrales",
				icon: "ApertureIcon",
				route: "corrales-sr",
				roles: [1, 4, 6],
			},
			{
				title: "Calendario Lotes",
				icon: "CalendarIcon",
				route: "calendar-sr",
				roles: [1, 4, 6],
			},
			{
				title: "Inseminación",
				icon: "ApertureIcon",
				route: "insemination-sr-pendientes",
				roles: [1, 4, 6],
			},
			
		],
		roles: [1, 4, 6],
	},
];
