var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
			{
				expanded:
					!_vm.isVerticalMenuCollapsed ||
					(_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
			},
			_vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded mb-2"},[_vm._t("header",[_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('b-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"filter":"brightness(1.5)"},attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1),_c('span',[_c('h2',{staticClass:"brand-text"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])])])])],1)])],{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('VuePerfectScrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
					_vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
				}}},[_c('VerticalNavMenuItems',{staticClass:"navigation navigation-main",attrs:{"items":_vm.navMenuItems}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }