export default [
  {
    title: "Cerebro",
    icon: "ApertureIcon",
    route: "brain-menu",
    requiresAdmin: true,
    requiresEncastador: false,
    requiresInvitado: false,
    roles: [1,6]
  }
];
